import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/OverOns.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import { BannerCustom } from 'components/Elements/Elements'
import BrandSlider from 'components/Brands/BrandSlider'
import CustomLink from 'components/CustomLink'

// Third Party
import _ from 'lodash'


function OverOns() {

  const {
    overOns: {
      acf: {
        page_content: {
          banner: overOnsBanner,
          banner2: overOnsBanner2,
          content: overOnsContent,
          image: {
            localFile: {
              childImageSharp: {
                fluid: overOnsImage
              }
            }
          }
        }
      },
      yoast_meta: yoast
    },
    medewerkers: {
      edges: medewerkersList
    }
  } = useStaticQuery(graphql`
  {
    overOns: wordpressPage(wordpress_id: {eq: 11}) {
      acf {
        page_content {
          banner {
            button_text
            text
          }
          banner2 {
            button_text
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          content
        }
      },
      yoast_meta {
        name
        content
        property
      }
    },
    medewerkers: allWordpressWpMedewerker(sort: {order: ASC, fields: date}) {
      edges {
        node {
          acf {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            link
          }
          title
        }
      }
    }
  }`)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="over-ons">
        <h1 className="d-none">Kooloos Zon en Huis | Thuis in elk seizoen</h1>
        <BackgroundImage loading="eager" alt="" className="over-ons-image" fluid={overOnsImage} />
        <BannerCustom to="/contact" className="color-background-secondary" bannerText={overOnsBanner.text} bannerButtonText={overOnsBanner.button_text} />
        <div className="container py-5">
          <Content content={overOnsContent} />
          <h2 className="font-size-xl font-weight-xl text-center pt-5 pb-3">Ons Team</h2>
          <div className="row">
            {_.map(medewerkersList, (medewerker, index) => (
              <Medewerker key={index} className="col-12 col-lg-4" medewerker={medewerker.node} />
            ))}
          </div>
        </div>
        <BannerCustom to="/contact" className="color-background-main" bannerText={overOnsBanner2.text} bannerButtonText={overOnsBanner2.button_text} />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default OverOns

const Medewerker = ({ medewerker, className }) => (
  <div className={`medewerker ${className ? `${className}` : ``}`}>
    <CustomLink to={medewerker.acf.link}>
      <Img className="medewerker-image" fluid={medewerker.acf.image.localFile.childImageSharp.fluid} />
      <div className="medewerker-black-bar color-background-main position-relative t-minus-20 p-2 w-70 d-flex justify-content-start align-items-center">
        <p className="m-0 font-weight-xl font-size-m color-text-light">{medewerker.title}</p>
      </div>
    </CustomLink>
  </div>
)